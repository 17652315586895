// import _waterData from "./water_data_2024.json";
import { getInflowOption } from "./apis";

// export const WaterData = _waterData;
export const DamName = {
  NPL: "อ่างเก็บน้ำหนองปลาไหล",
  DK: "อ่างเก็บน้ำดอกกราย",
  PS: "อ่างเก็บน้ำประแสร์",
  KY: "อ่างเก็บน้ำคลองใหญ่",
  "DK+KY+NPL": "รวม 3 อ่างเก็บน้ำ: ดอกกราย, คลองใหญ่, หนองปลาไหล",
};

export const manualOption = [
  {
    id: "manual",
    name: "Manual",
    value: "Manual",
    percentage: [
      {
        id: `Manual`,
        name: `Manual`,
        value: "Manual",
      },
    ],
  },
];
export function GetYearRangeWithDamName(damName, waterData) {
  const years = [];
  for (let i = 0; i < waterData.length; i++) {
    if (years.includes(waterData[i].year) || waterData[i].year < 2552) {
      continue;
    }
    years.push(waterData[i].year);
  }
  return years;
}

function getMonths(inflowName) {
  let months = [];
  for (let i = 1; i <= 12; i++) {
    months.push({
      id: i.toString(),
      name: `${inflowName} ${i} เดือน`,
      value: `${inflowName} ${i}`,
    });
  }
  return months;
}

export async function GetInflowOptions(selectedDam) {
  let response = await getInflowOption(selectedDam);
  let waterCapacity = response.water_level_data;
  let inflowOption = response.options.map((inflow) => {
    let inflowLabel = `${inflow}`;
    return {
      id: inflowLabel,
      name: inflowLabel,
      percentage: [
        {
          id: `${inflowLabel}-25%`,
          name: `${inflowLabel} 25%`,
          months: getMonths(`${inflowLabel} 25%`),
        },
        {
          id: `${inflowLabel}-50%`,
          name: `${inflowLabel} 50%`,
          months: getMonths(`${inflowLabel} 50%`),
        },
        {
          id: `${inflowLabel}-75%`,
          name: `${inflowLabel} 75%`,
          months: getMonths(`${inflowLabel} 75%`),
        },
        {
          id: `${inflowLabel}-100%`,
          name: `${inflowLabel} 100%`,
          months: getMonths(`${inflowLabel} 100%`),
        },
      ],
    };
  });
  inflowOption = manualOption.concat(inflowOption);
  return { inflowOption, waterCapacity };
}
