import {
  TextField
} from "@mui/material";
import { ManualInputLengthCount } from "../util/count_manual_input";

export function ManualOutflowForm({
    manualOutflows,
    setManualOutflows,
    idx,
    predictionPeriod,
  }) {
    return (
      <>
        <TextField
          id="tags-filled"
          label="Manual Outflow"
          placeholder="กรอกค่าทีละเดือน โดยสามารถกด Enter เพื่อแยกค่าในแต่ละเดือน"
          variant="filled"
          multiline
          error={manualOutflows[idx].length > predictionPeriod}
          maxRows={4}
          type="number" // Use type="text" with comma separation
          helperText={
            manualOutflows[idx].length > predictionPeriod
              ? "กรุณากรอกค่าไม่เกินจำนวนเดือนที่เลือก"
              : "(ล้าน ลบ.ม./เดือน)" +
                (predictionPeriod - ManualInputLengthCount(manualOutflows[idx]) >
                0
                  ? `, ใส่ได้ ${predictionPeriod} เดือน, คงเหลือ ` +
                    (predictionPeriod -
                      ManualInputLengthCount(manualOutflows[idx])) +
                    " เดือน"
                  : ", ครบแล้ว")
          }
          value={manualOutflows[idx].join(", ")} // Join numbers with comma for display
          onChange={(event) => {
            if (event.target.value.endsWith(",")) {
              event.target.value += " ";
            }
            // Validate input: Only number
            if (isNaN(event.target.value.split(", ").pop())) {
              event.target.value = event.target.value.slice(0, -1);
            }
            let newNumbers = event.target.value.replace("\n", ", ").split(", ");
            // Input = "delete" => pop last element
            if (event.nativeEvent.inputType === "deleteContentBackward") {
              setManualOutflows((prev) => {
                let newManualOutflows = [...prev];
                newManualOutflows[idx].pop();
                return newManualOutflows;
              });
              return;
            }
            setManualOutflows((prev) => {
              let newManualOutflows = [...prev];
              newManualOutflows[idx] = newNumbers;
              return newManualOutflows;
            });
            // }
            // setManualOutflows(newNumbers.filter((num) => !isNaN(num))); // Filter out non-numbers
          }}
        />
      </>
    );
  }